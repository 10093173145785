<template>
  <v-card rounded="4" elevation="0" class="ma-md-4 footer-card primary">
    <v-card-title>
      {{ $t(`footerCards.${cardName}.title`) }}
    </v-card-title>
    <v-card-subtitle>
      {{ $t(`footerCards.${cardName}.subtitle`) }}
    </v-card-subtitle>
    <v-img
      :src="$t(`footerCards.${cardName}.imgPath`)"
      :alt="'Immagine ' + $t(`footerCards.${cardName}.title`)"
      class="py-2"
    >
      <v-card-actions class="d-flex justify-center my-3 pb-5">
        <v-btn
          depressed
          class="primary"
          :to="$t(`footerCards.${cardName}.link`)"
        >
          {{ $t(`footerCards.${cardName}.button`) }}
        </v-btn>
      </v-card-actions>
    </v-img>
  </v-card>
</template>
<style lang="scss">
.footer-card.v-card {
  border-radius: $border-radius-root;
  .v-card__title {
    font-size: 1.5rem !important;
    line-height: 2rem;
    font-weight: bold !important;
    color: $white;
  }
  .v-card__subtitle {
    font-size: 1.1rem;
    height: 110px;
    color: $white;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-card__subtitle {
      font-size: 1.1rem;
      height: 150px;
      color: $white;
    }
  }
  .v-card__actions {
    align-items: flex-end;
    height: 100%;
  }
}
</style>
<script>
export default {
  name: "FooterCard",
  props: {
    cardName: { type: String, required: true }
  }
};
</script>
